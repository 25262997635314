import React from "react";

const Hello = () => {
  return (
    <>
      <iframe
        style={{
          height: "100vh",
          width: "100%"
        }}
        src="https://www.youtube.com/embed/9zU3iJN_X3E?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;mute=1"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      <iframe
        width="100%"
        height="0"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/614778672%3Fsecret_token%3Ds-9dWPo&color=%23ff5500&auto_play=true&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false#t=30s"
      />
    </>
  );
};

export default Hello;
