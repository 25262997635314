import React from "react";
import { Global } from "@emotion/core";
import { css } from "theme-ui";
import { Layout, Main, Container } from "theme-ui/layout";
import Header from "./header";

// TODO add container back in

export default props => (
  <>
    <Global
      styles={css({
        "*": {
          boxSizing: "border-box"
        },
        html: {
          height: "100%"
        },
        body: {
          margin: 0,
          color: "text",
          bg: "background",
          height: "100%"
        }
      })}
    />
    <Layout>
      <Header />
      <Main>{props.children}</Main>
    </Layout>
  </>
);
